<template>
    <Select v-model="selectModel"
            ref="select"
            title="Код подразделения"
            label="value"
            itemsKey="value"
            :error="error"
            @input="input"
            :name="name"
            fieldLabel="data.code"
            @listClick="listClick"
            :beforeItem="item => item.data.code"
            fieldMask="###-###"
            :list="list"/>
</template>

<script>
import Select from '@index/components/common/select/Select'

import axios from 'axios'

const DADATA_URL = process.env.VUE_APP_DADATA_URL + 'fms_unit'
const TOKEN = process.env.VUE_APP_DADATA_TOKEN

export default {
    name: 'FMSSearch',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        autoTab: {
            type: String,
            default: '',
        },
        model: {
            required: true
        },
        error: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            list: [],
            timer: null,
        }
    },
    computed: {
        selectModel: {
            get() {
                return this.model
            },
            set(v) {
                if (!v)
                    v = {
                        value: '',
                        data: {}
                    }

                this.$emit('change', v)

                this.$nextTick(() => {
                    if (v.data.code?.length === 7) {
                        const nextField = document.querySelector(`input[name="${this.autoTab}"]`)
                        nextField && nextField.focus()
                    }
                })
            }
        },
        select() {
            return this.$refs.select
        },
        field() {
            return this.select.field
        },
    },
    methods: {
        listClick() {
            setTimeout(() => {
                this.select.isFocus = false
                this.field.blur()
            })
        },
        getList(v) {
            clearTimeout(this.timer);

            this.timer = setTimeout(() => {
                if (!v) {
                    this.list = []
                    return
                }

                const data = {
                    count: 5,
                    restrict_value: true,
                    query: v
                }

                axios.post(DADATA_URL, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${TOKEN}`
                    }
                }).finally(() => {
                    this.showLoading = 0
                }).then((response) => {
                    if (response.data && response.data.suggestions && response.data.suggestions.length > 0) {
                        this.list = response.data.suggestions
                    } else {
                        this.list = []
                    }
                })
            }, 600);
        },
        input(v) {
            this.getList(v);

            this.selectModel = {
                value: '',
                data: {
                    code: v
                }
            }
        },
    },
    components: {
        Select
    }
}
</script>
