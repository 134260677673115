<template>
  <div
    class="passport-information"
  >
    <div class="passport-information__combined">
      <Field
          v-model="form.passportData.passportseries"
          :error="formErrors.passportData.passportseries"
        title="Серия"
        mask="## ##"
          autoTab="number"
          valueWithoutMask
      />
      <Field
          v-model="form.passportData.passportnumber"
          :error="formErrors.passportData.passportnumber"
        title="Номер"
          name="number"
          autoTab="issuecode"
        mask="### ###"
          valueWithoutMask
      />
      <FMSCode
          v-model="issuerCode"
          :error="formErrors.passportData.passportissuecode"
        title="Код подразделения"
          name="issuecode"
          :autoTab="form.passportData.passportissuer ? 'dateissue' : 'issuer'"
      />
    </div>
      <div class="passport-information__issuer">
          <Field
              title="Кем выдан"
              v-model="form.passportData.passportissuer"
              :error="formErrors.passportData.passportissuer"
              name="issuer"
          />
          <Date
              name="dateissue"
              title="Дата выдачи"
              v-model="form.passportData.passportissuedate"
              :error="formErrors.passportData.passportissuedate"
          />
      </div>
  </div>
</template>

<script>
import './passport-information.scss'
import Field from '@index/components/common/field/Field'
import Date from '@index/components/common/field/Date'
import FMSCode from '@index/components/application/search/FMSCode'

import validation from '@index/mixins/validation'
import Validation from '@index/ext/validation/Validation'

import { year, month, day } from '@index/helpers/date/currentDate'
const TODAY = `${day}.${month}.${year}`

const ISSUE_DATE_VALIDATE = [Validation.DATE, [Validation.DATE_LESS, TODAY]]

import { mapGetters } from 'vuex'

export default {
  name: 'PassportInformation',
    mixins: [
        validation
    ],
  data() {
    return {
        issuerFNS: null,
        form: {
            contactData: {
                addrcity: '',
                birthday: ''
            },
            passportData: {
                passportissuecode: '',
                passportnumber: '',
                passportseries: '',
                passportissuer: '',
                passportissuedate: ''
            }
        },
        formRules: {
            contactData: {
                addrcity: [Validation.REQUIRED]
            },
            passportData: {
                passportissuecode: [
                    Validation.REQUIRED,
                    [Validation.MIN, 6],
                    [Validation.MAX, 6]
                ],
                passportnumber: [
                    Validation.REQUIRED,
                    [Validation.MIN, 6],
                    [Validation.MAX, 6]
                ],
                passportseries: [
                    Validation.REQUIRED,
                    [Validation.MIN, 4],
                    [Validation.MAX, 4]
                ],
                passportissuer: [Validation.REQUIRED],
                passportissuedate: ISSUE_DATE_VALIDATE
            }
        },
        customErrors: {
            passportData: {
                passportissuedate: {
                    [Validation.DATE_LESS]: 'Дата не может быть больше текущей',
                    [Validation.DATE_MORE]: 'Некорректная дата выдачи паспорта'
                },
            }
        }
    }
  },
    computed: {
        issuerCode: {
            set(v) {
                this.issuerFNS = v;
                this.form.passportData.passportissuecode = String(v.data.code).replace(/[^\d]/g, '');
                this.form.passportData.passportissuer = v.value || this.form.passportData.passportissuer
            },
            get() {
                if (this.issuerFNS) {
                    return this.issuerFNS
                }

                return {
                    value: '',
                    data: {
                        code: this.user.passportData.passportissuecode
                    }
                }
            }
        },
      ...mapGetters({
          user: 'application/user'
      })
    },
    watch: {
        'form.contactData.birthday'(v) {
            if (!v)
                return

            const passportRules = this.formRules.passportData
            const [birthDay, birthMonth, birthYear] = v.split('.').map(i => +i)

            let age  = year - birthYear
            if (birthMonth > month || (birthMonth === month && birthDay > day))
                age--

            if (!birthYear || birthYear.length < 4)
                passportRules.passportissuedate = ISSUE_DATE_VALIDATE
            else if (age >= 45)
                passportRules.passportissuedate = [
                    ...ISSUE_DATE_VALIDATE,
                    [Validation.DATE_MORE, `${birthDay}.${birthMonth}.${birthYear + 45}`],
                ]
            else if (age >= 20)
                passportRules.passportissuedate = [
                    ...ISSUE_DATE_VALIDATE,
                    [Validation.DATE_MORE, `${birthDay}.${birthMonth}.${birthYear + 20}`],
                ]
            else if (age >= 18)
                passportRules.passportissuedate = [
                    ...ISSUE_DATE_VALIDATE,
                    [Validation.DATE_MORE, `${birthDay}.${birthMonth}.${birthYear + 14}`],
                ]

            this.formIsSubmit && this.validate(false)
        },
    },
    created() {
        this.initData()
    },
    methods: {
      initData() {
          this.form.contactData.addrcity = this.user.contactData.addrcity
          this.form.passportData.passportissuecode = String(this.user.passportData.passportissuecode).replace(/[^\d]/g, '')
          this.form.passportData.passportnumber = this.user.passportData.passportnumber
          this.form.passportData.passportseries = this.user.passportData.passportseries
          this.form.passportData.passportissuedate = this.user.passportData.passportissuedate
          this.form.passportData.passportissuer = this.user.passportData.passportissuer
          this.form.birthday = this.user.contactData.birthday
      }
    },
  components: {
    Field,
      Date,
      FMSCode
  }
}
</script>
