<template>
    <form
        novalidate
        @submit.prevent="validate"
        class="personal-information-view"
    >
        <Title>
            Паспортные данные
        </Title>

        <PassportInformation
            ref="passportInformation"
            class="personal-information-view__passport"
        />

        <div
            class="personal-information-view__actions"
        >
            <Button
                type="submit"
            >
                Продолжить
            </Button>
            <Button
                back
                @click.native="back"
            >
                Назад
            </Button>
        </div>
    </form>
</template>

<script>
import './personal-information-view.scss'

import PassportInformation from '@index/components/application/passport-information/PassportInformation'
import Title from '@index/components/gui/title/Title'
import Button from '@index/components/gui/button/Button'

import store from '@index/store'

import setMask from '@index/helpers/string/setMask'
import openRoute from '@index/helpers/router/openRoute'

export default {
    name: 'PersonalInformationView',
    mounted() {
        this.$refs.passportInformation.form.contactData.birthday = this.$store.state.application.data.contactData.birthday
    },
    methods: {
        validate() {
            this.$refs.passportInformation.validate()

            this.$refs.passportInformation.isValid && this.submit();
        },
        async submit() {
            const { next_step, noValid } = await this.$store.dispatch('application/sendPas', {
                passportData: {
                    ...this.$refs.passportInformation.form.passportData,
                    passportissuecode: setMask(this.$refs.passportInformation.form.passportData.passportissuecode, '###-###')
                }
            })

            if (noValid && Object.keys(noValid)) {
                const passportInfoError = this.$refs.passportInformation.formErrors.passportData;

                passportInfoError.passportissuecode = (noValid.passportissuecode === false && 'Невалидное значение') || ''
                passportInfoError.passportnumber = (noValid.passportnumber === false && 'Невалидное значение') || ''
                passportInfoError.passportseries = (noValid.passportseries === false && 'Невалидное значение') || ''
                passportInfoError.passportissuer = (noValid.passportissuer === false && 'Невалидное значение') || ''
                passportInfoError.passportissuedate = (noValid.passportissuedate === false && 'Невалидное значение') || ''

                return;
            }

            openRoute(next_step)
        },
        back() {
            this.$router.push({ name: 'PersonalInfo' })
        },
    },
    components: {
        PassportInformation,
        Title,
        Button,
    },
    beforeRouteEnter(to, from, next) {
        const { contactData: { phone } } = store.getters['application/user']

        if (phone) {
            next()
        } else {
            store.commit('application/load', false)
            next({ name: 'ContactInfo' })
        }
    }
};
</script>

